import React from "react";
import Navigation from "../components/navigation";
import Footer from "../components/footer";

class Layout extends React.Component {
  state = {
    showMenu: false,
    scrollClass: "",
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    })
  }

  rollOff = () => {
    this.setState({ showMenu: "" })
  }

  //Add class when scrolling
  componentDidMount() {
    window.addEventListener("scroll", () => {
      let scrollClass = "scrolling"
      if (window.scrollY <= 80) {
        scrollClass = "parked"
      }
      this.setState({ scrollClass })
    })
  }

  componentWillUnmount() {
    // Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const menuVis = this.state.showMenu ? "active" : ""
    const scrollClass = this.state.scrollClass
    return (
      <>
        <Navigation scrollClass={scrollClass} menuVis={menuVis} toggleMenu={this.toggleMenu} />
        <div className="screen-max mx-auto bg-white page-content">{this.props.children}</div>
        <Footer />
      </>
    );
  }
}

export default Layout;
