import React from "react";

const Footer = () => {
  return (
    <footer className="bg-navy py-12 mx-auto screen-max">
      <p className="text-center text-white text-xl font-bold pb-0 mb-0">&copy; {new Date().getFullYear()}, Paid for by Friends of Judge Dawn Moody 2022 🇺🇸</p>
    </footer>
  );
}

export default Footer;