import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import { FaBars, FaPaypal, FaHandsHelping } from "react-icons/fa";

const Navigation = ({ scrollClass, menuVis, toggleMenu }) => (
  <nav className={`primary-nav screen-max mx-auto left-0 right-0 fixed w-full z-50 py-6 px-4 transition-all duration-300 border-b-2 border-transparent ${scrollClass} ${menuVis}`}>
    <div className="menu-icon inline-block lg:hidden hover:bg-opacity-75 transition-all bg-red rounded-full p-4 w-16 h-16 float-right" onKeyDown={toggleMenu} onClick={toggleMenu}>
      <FaBars className="text-white text-3xl" />
    </div>
    <Link to="/">
      <StaticImage src="../images/logo.png" placeholder="none" alt="logo" layout="constrained" height={70} objectFit="contain" objectPosition="left center" className="logo w-60 md:w-auto mt-2 p" />
    </Link>
    <ul className="hidden lg:inline-block lg:float-right">
      <li><Link to="/" className="inline-block">Home</Link></li>
      <li><Link to="/about">About </Link></li>
      <li><Link to="/supporters">Supporters </Link></li>
      <li><Link to="/volunteer" className="btn gray"><FaHandsHelping className="inline" /> Volunteer </Link></li>
      <li><form action="https://www.paypal.com/donate" method="post" target="_top">
        <input type="hidden" name="hosted_button_id" value="U64QK2AY5LQCN" />
        <button type="submit" className="btn"><FaPaypal className="inline" /> Donate</button>
        <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
      </form></li>
    </ul>
  </nav>
)

export default Navigation;